import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OktaAuthServiceService } from 'src/app/services/okta-auth-service.service';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent implements OnInit {
  courseId: string | null = null;
  constructor(public authService:OktaAuthServiceService,
    private route: ActivatedRoute
  ) { 
    debugger;
   
    this.route.fragment.subscribe(fragment => {
      console.log(fragment); // This logs the entire fragment string
      if (fragment) {
        const params = fragment.split('?')[1];
        if (params) {
          const courseIdParam = params.split('&').find(param => param.startsWith('courseId='));
          if (courseIdParam) {
            this.courseId = courseIdParam.split('=')[1];
            console.log(this.courseId);
            localStorage.setItem('redirect',this.courseId)
          }
        }
      }
    });
    // localStorage.setItem('redirect',this.id)
    // this.authService.login();
  }

  ngOnInit() {
    this.authService.checkSessionOkta().subscribe((res:any)=>{
      if(res.agsessionme == 'true'){
        if(res.TTL == -1){
          this.authService.createSession().subscribe((res:any)=>{
            this.authService.checkAuthorize();
          })
        }else if(res.TTL > -1){
          this.authService.checkping().subscribe((res:any)=>{
    
            this.authService.checkAuthorize()
          })
        }else{
          this.authService.checkping().subscribe((res:any)=>{
            this.authService.checkAuthorize()
          })
        }
      }else{
        // this.authOktaService.login();
        this.authService.login();

      }
    })
  }

}
